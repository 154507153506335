exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-basic-jsx": () => import("./../../../src/templates/basic.jsx" /* webpackChunkName: "component---src-templates-basic-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

